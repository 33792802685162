<template>
  <v-row class="ma-4" dense align="center">
    <v-col cols="12" class="header mb-2 pl-2 py-4">
      <span style="font-size: 24px; font-weight: 400">{{
        $t("ข้อมูลเอกสาร")
      }}</span>
      <!-- <v-btn style="float: right" color="grey" dark @click="savedraft()">
        <v-icon class="mr-1">mdi-file-edit</v-icon>save draft</v-btn
      > -->
    </v-col>
    <v-card outlined width="100vw" class="pa-4">
      <v-row class="mt-2">
        <v-col cols="6" md="2" class="mt-2">
          <span>{{ $t("Document type") }}</span>
        </v-col>
        <v-col cols="6" md="4" class="ml-n15">
          <v-autocomplete
            v-model="form.documentId"
            :error-messages="!documentId ? 'This field is required' : ''"
            :items="itemsdocuments"
            :label="
              EngLang === true
                ? 'Select selecteddocument_type'
                : 'เลือก document'
            "
            :item-text="EngLang ? 'document_type' : 'document_type'"
            item-value="id"
            solo
            dense
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" md="4">
          <div>ต้องการให้ใส่ pin ก่อนเปิดเอกสารหรือไม่</div>
        </v-col>
        <v-col cols="4" md="4" class="mt-n5 ml-n10">
          <v-radio-group v-model="isDepartment" row solo dense>
            <v-radio :value="1" label="Yes"></v-radio>
            <v-radio :value="0" label="No"></v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="6" md="6">
          <span>{{ $t("Document No") }}</span>
          <v-text-field v-model="form.document_no" solo dense></v-text-field>
        </v-col>
        <v-col cols="6" md="6">
          <span>{{ $t("full name") }}</span>
          <v-text-field v-model="form.fullname" solo dense></v-text-field>
        </v-col>
        <v-col cols="6" md="6">
          <span>{{ $t("Note") }}</span>
          <v-text-field v-model="form.note" solo dense></v-text-field>
        </v-col>
        <v-col cols="6" md="6">
          <span>{{ $t("ETC") }}</span>
          <v-text-field v-model="form.etc" solo dense></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-row class="pa-9" justify="end">
            <v-btn color="#ff78ac " dark @click="submit()"> บันทึก </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      itemsdocuments: [],
      getOneItemAffiliation: [],
      itemChangeLang: "",
      userData: [],
      EngLang: true,
      nationalityItems: [],
      itemsroyal_thai_embassy: [],
      itemsstudent_types: [],
      itemsexchange_visiting_student: [],
      itemsaffiliation_faculty: [],
      itemslevel_Of_education: [],
      itemsdepartment: [],
      itemsvisa_types: [],
      itemsReasonForVisaCancellation: [],
      itemscovid19_vaccine: [],
      itemsTitle: [
        {
          textTH: "นาย",
          textEN: "Mr.",
          value: "Mr",
        },
        {
          textTH: "นางสาว",
          textEN: "Ms.",
          value: "Ms",
        },
        // {
        //   textTH: "นาง",
        //   textEN: "Mrs.",
        //   value: "Mrs",
        // },
      ],
      date_of_birthMenu: false,
      edu_infor_startMenu: false,
      edu_infor_endMenu: false,
      visa_expiry_dateMenu: false,
      stdeunt_visa_termination_dateMenu: false,
      form: {
        attachFile1: "",
        attachFile2: "",
        attachFile3: "",
        attachFile4: "",
        attachFile5: "",
        attachFile6: "",
        attachFile7: "",
        internalMemoFile: "",
        qrcode: "https://www.npmjs.com/package/vue-qrcode",

        student_id: null,
        mRoyalThaiEmbassyId: null,
        title: "",
        give_name: "",
        middle_name: "",
        family_name: "",
        nationality: "",
        date_of_birth: null,
        email: "",
        passport_number: "",
        boostershot: [],
        mStudentTypeId: null,
        mVisaTypeId: null,
        mLevelOfEducationId: null,
        mAffiliationFacultyId: null,
        mExchangeVisitingStudentId: null,
        mDepartmentId: null,
        edu_infor_start: null,
        edu_infor_end: null,
        email_cc: "",
        auth_signa_doc_no: "",
        auth_signa_link_dean_director: "",
        auth_signa_operation_full_name: "",
        auth_signa_position: "",
        auth_signa_for: "",
        auth_signa_file_upload: "",
        coor_con_link: "",
        coor_con_full_name: "",
        coor_con_tel: "",
        coor_con_email: "",
        email_cc: "",
        edu_infor_extend_study_period: null,
        visa_expiry_date: null,
        stdeunt_visa_termination_date: null,
        mReasonForVisaCancellationId: null,
        edu_infor_file_upload: "",

        appointment_acting: 0,
        appointment_acting_name: "",
        appointment_acting_position: "",
        appointment_acting_for: "",
      },
      bostername: "",
      bosterdate: "",
      e1: 1,
      item: [
        {
          id: 0,
          name_th: "t1",
          name_en: "test1",
          qrcode: "https://www.npmjs.com/package/vue-qrcode",
        },
      ],
    };
  },
  created() {
    const lang = localStorage.getItem("lang");
    if (lang == "en") {
      this.EngLang = true;
      this.itemChangeLang = "name_en";
    } else if (lang == "th") {
      this.EngLang = false;
      this.itemChangeLang = "name_th";
    }
    this.getdocuments();
    this.getEmbassy();
    this.getStudentTypes();
    this.getExchangeVisitingStudent();
    // this.getAffiliationFaculty();
    // this.getLevelOfEducation();
    // this.getDepartment();
    this.getVisaTypes();
    this.getData();
    this.getAllNationality();
    this.getAllReasonForVisaCancellation();
    this.userData = JSON.parse(
      Decode.decode(localStorage.getItem("userCUdata"))
    );
    console.log("thisuserData", this.userData);
  },
  methods: {
    onClickFile(val) {
      window.open(val, "_blank");
    },
    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },
    async submitFile(questionNo) {
      console.log("this.$refs[questionNo]", this.$refs[questionNo]);
      if (this.$refs[questionNo]) {
        if (this.$refs[questionNo].files[0]) {
          // this.loading = true;
          let formData = new FormData();
          formData.append("files", this.$refs[questionNo].files[0]);
          formData.append("filename", this.$refs[questionNo].files[0].name);
          formData.append("type", questionNo);
          // formData.append("userId", this.userId);
          console.log("formData", formData);
          const auth = {
            headers: {
              "Content-Type": "multipart/form-data",
              // Authorization: `Bearer` + " " + this.checkuser.token,
            },
          };
          console.log("auth", auth);
          this.uploaded = true;
          const response = await this.axios.post(
            `${process.env.VUE_APP_API}/document_qr_code`,
            formData,
            auth
          );
          if (questionNo == "form.attachFile1") {
            this.form.attachFile1 = response.data.data.path;
          }
          if (questionNo == "form.attachFile2") {
            this.form.attachFile2 = response.data.data.path;
          }
          if (questionNo == "form.attachFile3") {
            this.form.attachFile3 = response.data.data.path;
          }
          if (questionNo == "form.attachFile4") {
            this.form.attachFile4 = response.data.data.path;
          }
          if (questionNo == "form.attachFile5") {
            this.form.attachFile5 = response.data.data.path;
          }
          if (questionNo == "form.attachFile6") {
            this.form.attachFile6 = response.data.data.path;
          }
          if (questionNo == "form.attachFile7") {
            this.form.attachFile7 = response.data.data.path;
          }
          if (questionNo == "form.internalMemoFile") {
            this.form.internalMemoFile = response.data.data.path;
          }
          // if (questionNo == "form.") {
          //   this.pic4 = response.data.data.path;
          // }
          // console.log('this.pic1',this.pic1,'this.pic2',this.pic2)
          // .then(function () {
          console.log("SUCCESS!!");
          console.log("response", response);
          // this.uploaded = false;
        }
      }
    },
    async getOneAffiliation(mStudentTypeId) {
      this.getOneItemAffiliation = [];
      this.form.mAffiliationFacultyId = null;
      this.form.mLevelOfEducationId = null;
      this.form.mDepartmentId = null;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/affiliation_faculty?mStudentTypeId=${mStudentTypeId}`
      );
      this.getOneItemAffiliation = response.data.data;
    },
    async getOneLevelEducation(mAffiliationFacultyId) {
      this.getInfo(mAffiliationFacultyId);
      this.itemslevel_Of_education = [];
      this.form.mLevelOfEducationId = null;
      this.form.mDepartmentId = null;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/level_Of_education?mAffiliationFacultyId=${mAffiliationFacultyId}`
      );
      this.itemslevel_Of_education = response.data.data;
      this.getOneDepartment(mAffiliationFacultyId);
    },

    async getOneDepartment(mAffiliationFacultyId) {
      this.itemsdepartment = [];
      this.form.mDepartmentId = null;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/department?mAffiliationFacultyId=${mAffiliationFacultyId}`
      );
      console.log("response department", response.data.data);
      this.itemsdepartment = response.data.data;
    },

    async getInfo(mAffiliationFacultyId) {
      this.findInfo = this.getOneItemAffiliation.find(
        (x) => x.id === parseInt(mAffiliationFacultyId)
      );

      this.itemsChancellor = [];
      this.findInfo.act_instead_agent_th || this.findInfo.act_instead_agent_en
        ? this.itemsChancellor.push({
            id: 1,
            name_th: this.findInfo.act_instead_agent_th,
            name_en: this.findInfo.act_instead_agent_en,
          })
        : "";

      this.findInfo.dean_director_th || this.findInfo.dean_director_en
        ? this.itemsChancellor.push({
            id: 2,
            name_th: this.findInfo.dean_director_th,
            name_en: this.findInfo.dean_director_en,
          })
        : "";
      this.form.auth_signa_link_dean_director = this.itemsChancellor[0].id;
      if (this.EngLang === true) {
        // this.form.auth_signa_link_dean_director =
        //   this.findInfo.dean_director_en;
        this.form.appointment_acting_name = this.findInfo.agent_en;
        this.form.appointment_acting_position = this.findInfo.position_agent_en;
        this.form.appointment_acting_for = this.findInfo.act_instead_agent_en;
      } else {
        // this.form.auth_signa_link_dean_director =
        //   this.findInfo.dean_director_th;
        this.form.appointment_acting_name = this.findInfo.agent_th;
        this.form.appointment_acting_name = this.findInfo.agent_th;
        this.form.appointment_acting_position = this.findInfo.position_agent_th;
        this.form.appointment_acting_for = this.findInfo.act_instead_agent_th;
      }
      if (this.findInfo.is_agent === true) {
        this.form.appointment_acting = 1;
      } else {
        this.form.appointment_acting = 0;
      }
    },

    async getAllReasonForVisaCancellation() {
      const response = await this.axios.get(
        `${
          process.env.VUE_APP_API
        }/mReasonForVisaCancellation?type=${"นิสิต(Student)"}`
      );
      console.log("response", response.data.data);
      this.itemsReasonForVisaCancellation = response.data.data;
      console.log(
        "itemsReasonForVisaCancellation",
        this.itemsReasonForVisaCancellation
      );
    },
    async getAllNationality() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/nationality`
      );
      this.nationalityItems = response.data.data;
      console.log("nationalityItems", this.nationalityItems);
    },
    async getData() {
      const id = this.$route.query.id;
      console.log("id", id);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/document_qr_code/${id}`
      );
      console.log("response document_qr_code", response.data.data);
      this.form = response.data.data;
      await this.checkAffiliation(this.form.mStudentTypeId);
      await this.getAffiliationFaculty(this.form.mStudentTypeId);
      await this.getLevelOfEducation(this.form.mAffiliationFacultyId);
      await this.getDepartment(this.form.mAffiliationFacultyId);
    },
    async getEmbassy() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/royal_thai_embassy`
      );
      console.log("response royal_thai_embassy", response.data.data);
      this.itemsroyal_thai_embassy = response.data.data;
    },
    async getStudentTypes() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/student_types`
      );
      console.log("response student_types", response.data.data);
      this.itemsstudent_types = response.data.data;
    },
    async getExchangeVisitingStudent() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/exchange_visiting_student`
      );
      console.log("response exchange_visiting_student", response.data.data);
      this.itemsexchange_visiting_student = response.data.data;
    },
    async getAffiliationFaculty(mStudentTypeId) {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/affiliation_faculty?mStudentTypeId=${mStudentTypeId}`
      );
      console.log("response affiliation_faculty", response.data.data);
      this.getOneItemAffiliation = response.data.data;
    },

    async checkAffiliation() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/affiliation_faculty`
      );
      this.getOneItemAffiliation = response.data.data;

      let findInfo = this.getOneItemAffiliation.find(
        (x) => x.id === parseInt(this.form.mAffiliationFacultyId)
      );
      this.itemsChancellor = [];
      this.itemsChancellor.push({
        id: 1,
        name_th: findInfo.act_instead_agent_th,
        name_en: findInfo.act_instead_agent_en,
      });
      this.itemsChancellor.push({
        id: 2,
        name_th: findInfo.dean_director_th,
        name_en: findInfo.dean_director_en,
      });
      if (this.EngLang === true) {
        this.form.auth_signa_link_dean_director =
          this.itemsChancellor[this.form.auth_signa_link_dean_director - 1].id;
        this.form.appointment_acting_name = findInfo.agent_en;
        this.form.appointment_acting_position = findInfo.position_agent_en;
        this.form.appointment_acting_for = findInfo.act_instead_agent_en;
      } else {
        this.form.auth_signa_link_dean_director =
          this.itemsChancellor[this.form.auth_signa_link_dean_director - 1].id;
        this.form.appointment_acting_name = findInfo.agent_th;
        this.form.appointment_acting_position = findInfo.position_agent_th;
        this.form.appointment_acting_for = findInfo.act_instead_agent_th;
      }
      if (findInfo.is_agent === true) {
        this.form.appointment_acting = 1;
      } else {
        this.form.appointment_acting = 0;
      }
    },
    async getLevelOfEducation(mAffiliationFacultyId) {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/level_Of_education?mAffiliationFacultyId=${mAffiliationFacultyId}`
      );
      console.log("response level_Of_education", response.data.data);
      this.itemslevel_Of_education = response.data.data;
    },
    async getDepartment(mAffiliationFacultyId) {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/department?mAffiliationFacultyId=${mAffiliationFacultyId}`
      );
      console.log("response department", response.data.data);
      this.itemsdepartment = response.data.data;
    },
    async getVisaTypes() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/visa_type`
      );
      console.log("response visa_Type", response.data.data);
      this.itemsvisa_types = response.data.data;
    },
    async savedraft() {
      // if (this.$refs.createForm.validate(true)) {
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("userCUdata"))
      );
      console.log("thisuserData", this.userData);
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      // console.log("this.form.mAddressId", this.form.mAddressId);
      const data = {
        status: "SAVE DRAFT",
        ...this.form,
      };
      console.log("predata", this.form);
      const id = this.$route.query.id;
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/document_qr_code/${id}`,
        // this.form,
        data,
        auth
      );
      console.log("createCate", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: this.$t("status.submitSuccess"),
          showConfirmButton: false,
          timer: 1500,
        });
        // this.$router.push("ManageVoucher");
      } else {
        this.$swal.fire({
          icon: "error",
          text: this.$t("status.submitUnsuccess"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
      // }
    },
    async getdocuments() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/documents`
      );
      console.log("response documents", response.data.data);
      this.itemsdocuments = response.data.data;
    },
    async submit() {
      this.$router.go(-1);
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.header {
  background-color: #de5c8e;
  padding: 10px;

  color: white;
  border-radius: 5px;
}
</style>